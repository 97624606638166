body{
}
.header-container {
    z-index: -1;
  }
  .sub-categories {
    position: absolute;
    top: calc(50% + 10px); /* Adjust the spacing between main category and sub-categories */
    left: 0; /* Initially set left position to 0 */
    width: 140px; /* Adjust the width of the sub-categories */
    background-color: #FF8A1F;
    border: none; /* Border around the sub-categories */
    padding: 5px;
    z-index: 100; /* Ensure sub-categories appear above other content */
    display: none; /* Initially hide sub-categories */
}



.sub-categories ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.sub-categories ul :hover{
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #e06e04;
  transition: 0.5s;
}

.sub-categories ul li {
    margin-bottom: 5px; /* Adjust spacing between sub-categories */
    
}

.sub-categories ul li a {
    color: white;
    text-decoration: none;
    display: block;
    font-weight: normal;
}

.sub-categories ul li a:hover {
 
}

.navlink:hover .sub-categories,
.sub-categories:hover {
    display: block; /* Show sub-categories when hovering over main category or its sub-categories */
    
}


/* Position sub-categories exactly below their main category */
.navlink {
    position: relative;
}

.navlink:hover .sub-categories {
    left: 0; /* Set left position to match the main category */
}


  .navlink{
    color: white;
    background: none;
  }

  .rdbtn{
    border-color: #e15001 !important;
  }

 .special{
  background-image: url('/public/featureimg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

 }


 .carousel-root {
  width: 100% !important;
  margin: auto !important;
  margin-top: 3% !important;
 
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
  
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
  border-radius: 50px !important;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #e15001 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
 
  border-radius: 50px;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
  .navlink{
    color: black;
    border-bottom: rgb(116, 115, 115) solid 1px;
  }
  .navlink:hover{
    border-bottom: none;
  }
}

.imp{
  z-index: 1000;
}
.client{
  color: #4545453b;
  letter-spacing: 3px;
}

.footer{
  background-color: #222;
  color: white;
}

.items{
  color: rgb(182, 182, 182) !important;
}

.header{
  
}

.intouchtxt{
  font-size: 70px;
  font-weight: bold;
  line-height: 90%;
  text-align: center;
}

.contactcontainer{
  background-color: #F97316;

  color: white;
}

.submitbtn{

  background-color: white !important;
  border-color: #F97316 !important;
  font-weight: 500;
  color:#F97316 !important;
  border-width: 3px;
}

.newsbtn{
  background-color: #F97316 !important;
 
  color:white !important;
  border: none;

}

.footerlogo{
  font-weight: 800;
}


.aisolutionsmainpage{
  height: 1000px !important;
}
.aimain{
  background-color: #f77d27;
  position: relative;
  overflow: hidden;
}
.aimainsp{
  background-image: url('https://i.imgur.com/BSsnBAL.png');
  background-position: center;
  background-size: cover;
}
.aimainsp1{
  background-image:linear-gradient(#00000059,0,#120f0d6a,#0000004f), url('https://image.cnbcfm.com/api/v1/image/106858086-2019-03-14T032130Z-1104988919-RC144D248F90-RTRMADP-3-CHINA-AUTOS-JPG?v=1616516394&w=1600&h=900');
  background-position: center;
  background-size: cover;
}

.aimainsp2{
  background-image:linear-gradient(#00000059,0,#120f0d6a,#0000004f), url('https://i2-prod.dailyrecord.co.uk/incoming/article26802666.ece/ALTERNATES/s1200d/0_MPR_WSH_260422_EVCharging_1.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp3{
  background-image:linear-gradient(#00000059,0,#120f0d6a,#0000004f), url('https://www.manufacturingtomorrow.com/images/upload/images/Depositphotos_47608785_s-2019(2).jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp4{
  background-image:linear-gradient(#00000059,0,#120f0d6a,#0000004f), url('https://static.vecteezy.com/system/resources/previews/028/697/568/non_2x/microscopic-components-form-an-elaborate-circuitry-pattern-on-the-electronic-board-ai-generated-photo.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp5{
  background-image:linear-gradient(#00000059,0,#120f0d6a,#0000004f), url('https://builtin.com/sites/www.builtin.com/files/styles/og/public/2024-03/Drones%201600x800.jpg');
  background-position: center;
  background-size: cover;
}
.aimainsp6{
  background-image:linear-gradient(#00000059,0,#120f0d6a,#0000004f), url('https://www.theupsstore.com/Image%20Library/theupsstore/interior-header/iph_3dprinting_1350x496.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp7{
  background-image:linear-gradient(#00000059,0,#120f0d6a,#0000004f), url('https://www.lboro.ac.uk/media/wwwlboroacuk/external/content/mediacentre/pressreleases/2019/11/solar-panel-sun-rise.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp8{
  background-image:linear-gradient(#00000059,0,#120f0d6a,#0000004f), url('https://www.brookings.edu/wp-content/uploads/2023/07/Smart-AI-city-e1689868164756.jpg?quality=75&w=1500');
  background-position: center;
  background-size: cover;
}

.aimainsp9{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://eleks.com/wp-content/uploads/3840x1300_AI-transform-supply-chain-2048x693.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp10{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://cdn.prod.website-files.com/620c0d2e51cac37f5958848f/6234c32fa43811596ae3a5db_AutomationAlley-Article-AITakesManufacturingBeyondIndustry40.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp11{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.simplilearn.com/ice9/free_resources_article_thumb/Healthcare_Pharma_Trends_for_2021.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp12{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.cognex.com/library/media/blogs/css-header/is-l38-backup-homepage-banner.jpg?sc_lang=en&h=552&w=1920&la=en&hash=7D36E7B5DDDAA4E88497585772F5B39D');
  background-position: center;
  background-size: cover;
}

.aimainsp13{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://img.freepik.com/premium-photo/agriculture-green-bean-plants-soybean-field-closeup-farming-profit-is-concept-soybe_410516-112983.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp14{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.agleader.com/wp-content/uploads/2019/06/SMS-Basic-Soil-Type-Map.png');
  background-position: center;
  background-size: cover;
}

.aimainsp15{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://p6t9c4y7.rocketcdn.me/wp-content/uploads/2023/04/Precision-Agriculture-Drone-3-1024x512.png');
  background-position: center;
  background-size: cover;
}

.aimainsp16{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://static.wixstatic.com/media/551ed3_9fb4e8d21d6d45618c5c162819688cf5~mv2.png/v1/crop/x_44,y_0,w_1200,h_719/fill/w_560,h_314,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/RD_WL_10x20_VIEW_2_004.png');
  background-position: center;
  background-size: cover;
}

.aimainsp17{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.boeing.com/content/theboeingcompany/us/en/defense/mq28/_jcr_content/root/container/container_858146469_/carousel/item_1698950039112.coreimg.85.1600.jpeg/1702307489796/ats-high-speed-taxi-960x640.jpeg');
  background-position: center;
  background-size: cover;
}

.aimainsp18{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.boeing.com/content/theboeingcompany/us/en/defense/mq28/_jcr_content/root/container/container_858146469_/carousel/item_1698272701406.coreimg.85.1600.jpeg/1702307491712/ats-gallery4-960x600.jpeg');
  background-position: center;
  background-size: cover;
}

.aimainsp19{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://img.freepik.com/free-photo/technology-particle-dots-5g-digital-corporate-background_53876-102624.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp20{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.rocketmine.com/wp-content/uploads/2018/08/MINE-COMBO-PILOT-LANDSCAPE-2.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp21{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://media.licdn.com/dms/image/C5612AQH0eAHz33JNZA/article-cover_image-shrink_720_1280/0/1520141776226?e=2147483647&v=beta&t=9kmHarmxK2fdqRE0WpFzGcWYwSsJsboPR0ZWDuWLJRU');
  background-position: center;
  background-size: cover;
}

.aimainsp22{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://cdn.britannica.com/10/222610-138-F0268996/britannica-insights-Phil-the-Fixer-renewable-energy.jpg?w=800&h=450&c=crop');
  background-position: center;
  background-size: cover;
}

.aimainsp23{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://sharework-project.eu/wp-content/uploads/2020/11/shutterstock_1154438635-scaled.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp24{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://as1.ftcdn.net/v2/jpg/04/52/17/22/1000_F_452172216_nvOohhDHsoEoU4YMOvG0w5mtjkQlkERH.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp25{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://img.machinedesign.com/files/base/ebm/machinedesign/image/2023/04/DMLS_process_2017_0944_hi_res.6447f612687ff.png?auto=format,compress&fit=crop&q=45&h=356&height=356&w=640&width=640');
  background-position: center;
  background-size: cover;
}

.aimainsp26{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://cdn2.hubspot.net/hub/2470564/hubfs/3d-printing-additive-manufacturing-processes-1.jpg?width=1500&name=3d-printing-additive-manufacturing-processes-1.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp27{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://hlhrapid.com/wp-content/uploads/2023/11/complex-cnc-machining-cost.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp28{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://d3hnfqimznafg0.cloudfront.net/images/Article_Images/ImageForArticle_1255_16243551402779003.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp29{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://cindasdata.com/img/carousel/extrusion1.jpg');
  background-position: center;
  background-size: cover;
}
.aimainsp30{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://scx2.b-cdn.net/gfx/news/hires/2023/graphene.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp31{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.logos3pl.com/wp-content/uploads/2023/08/the-revolutionizing-impact-of-internet-of-things-iot-in-supply-chain-management-logos-logistics.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp32{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.manutan.com/blog/medias/files/thumbsSource/A00161P2_1110x555.jpeg');
  background-position: center;
  background-size: cover;
}

.aimainsp33{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://green.org/wp-content/uploads/2024/01/The-Moral-Imperative-of-Responsible-Waste-Managements.png');
  background-position: center;
  background-size: cover;
}

.aimainsp34{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://mybatteryplus.com.au/cdn/shop/articles/lithium-ion-battery.jpg?v=1711609834');
  background-position: center;
  background-size: cover;
}

.aimainsp35{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://toyota-indus.com/wp-content/themes/astra/page-templates/Toyota-Electrification-and-Hybrid/dam/hybrid-electric/toyota-hev-why-choose-fuel-economy.jpg');
  background-position: center;
  background-size: cover;
}

.aimainsp36{
  background-image:linear-gradient(#0000008a,0,#120f0d93,#00000080), url('https://www.repsol.com/content/dam/repsol-corporate/es/energia-e-innovacion/Que-es-huerto-solar.jpg.transform/rp-rendition-md/image.jpg');
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  h1 {
      font-size: 1.5em !important;
      text-align: center !important;
  }

  p {
      font-size: 0.875em !important;
  }
}








.mainbtn{
 border-color: #f77d27 !important;
 border: #f77d27;
 color: #f77d27 ;
}

.bordercolor{
  border-color: #f77d27 !important;
 border: #f77d27;
 border-width: 2px !important;
}

.mainbtn2{
  background-color: white;
  color: #f77d27;
  border-color: #f77d27 !important;
 }

 .mainbtn2:hover{
  background-color: #f77d27;
  color: white ;
  transition: 0.5s;
  
 }

.mainbtn:hover{
  transition: 0.5s;
  color: white;
 
}

.smartcity{
 background-image: url('../pages/bg.png');
 background-attachment: fixed;
}

.agri{
  background-image: url('../pages/agribg.png');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
 }

.baiduaimain{
 
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
   /* 16:9 aspect ratio */
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}
@media (max-width: 767px) {
  .video-container {
      padding-bottom: 75%; /* 4:3 aspect ratio for mobile */
     ;
  }
  .responsive-iframe {
      width: 100%;
      height: 100%;
      
  }
}

.vid{
  overflow-x: hidden;
}
.baidubg{
  background-image: url('../pages/baidubg.png');
  background-position: center;
  background-size: cover;
}

.specialcont{
  background-color: #f77d27;
  color: white;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content-overlay {
  position: relative;
  z-index: 1;
  color: white; /* Ensure text is readable over the video */
}

